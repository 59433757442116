.carousel-container {
  position: relative;
}

.image-container {
  width: 100%;
  height: 90vh;
}
.image-resize {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.carousel-text-container {
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 10;
  color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.carousel-heading-text {
  font-size: 4vw;
  font-family: "Poppins", sans-serif;
}
.carousel-text {
  font-size: 1.2vw;
  line-height: 1.1;
  font-weight: 500;
}

.button-custom {
  width: 8vw;
  height: 2.7vw;
  font-size: 0.9vw;
  border-radius: 3vw;
}

.button-custom:hover {
  background-color: rgb(71, 219, 162);
  border-color: rgb(71, 219, 162);
}
.btn {
  padding: 0.7vw;
}

@media only screen and (max-width: 768px) {
  .image-resize {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .carousel-text-container {
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 10;
    color: white;
    left: 15%;
    top: 40%;
    transform: translate(-15%, -40%);
    text-align: left;
    width: 50%;
  }
  .carousel-heading-text {
    font-size: 10vw;
    line-height: 1.2;
  }
  .carousel-text {
    font-size: 2.3vw;
  }
  .button-custom {
    width: 16vw;
    height: 5.4vw;
    font-size: 1.8vw;
    border-radius: 3vw;
  }
}
