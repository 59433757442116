.slider-container-flag {
  width: 80%;
  margin: 8vw 8vw 4vw 8vw;
  text-align: center;
  font-size: 1.2vw;
}
.slick-prev:before,
.slick-next:before {
  color: #c71a23;
}
.flag-slider-image-container {
  padding: 2vw;
  background-color: white;
}
@media only screen and (max-width: 768px) {
  .slider-container-flag {
    margin: 18vw 18vw 4vw 18vw;
    font-size: 2.4vw;
    width: 60%;
  }
}
