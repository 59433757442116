.item-card-container {
  width: 80%;
  margin: 4vw 8% 2vw 8%;
  height: 45vw;
}
.item-card-title {
  font-size: 2vw;
  font-weight: 900;
}
.item-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.item-card {
  width: 33.333%;
  text-align: center;
  height: 50%;
}
.item-card-image-container {
  width: 20vw;
  height: 15vw;
  border-radius: 3vw;
  margin: 0 auto;
  object-fit: cover;
}
.item-card-image-resize {
  width: 100%;
  height: 100%;
  border-radius: 3vw;
}
.item-card-text {
  font-size: 1.5vw;
  color: rgb(179, 12, 12);
  font-weight: 600;
}
.item-card-image-container:hover {
  transform: scale(1.1);
  box-shadow: 0 0.6vw 1vw 0 rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 768px) {
  .item-card-container {
    width: 80%;
    margin: 4vw 8% 2vw 8%;
    height: 80vh;
  }
  .item-card-title {
    font-size: 4vw;
    font-weight: 900;
    text-align: center;
    margin-bottom: 5vw;
  }
  .item-card {
    width: 50%;
    text-align: center;
    height: 25%;
  }
  .item-card-image-container {
    width: 30vw;
    height: 25vw;
    border-radius: 3vw;
    margin: 0 auto;
    object-fit: cover;
  }
  .item-card-text {
    font-size: 2.5vw;
  }
}
