// .footer-image-container {
//   height: 30vw;
//   width: 100%;
//   background-image: url("../../images/658.jpg");
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-color: rgba(0, 0, 0, 0.7);
//   background-blend-mode: multiply;
//   background-position: 0 80%;
// }
.footer-image-container {
  height: 20vw;
  width: 100%;
  background-image: url("../../images/658.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
  background-position: 0 80%;
}
.footer-container {
  display: flex;
  color: white;
  padding: 6vw 15% 6vw 15%;
}
.footer-left-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
// .footer-left-container {
//   width: 50%;
// }
.footer-right-container {
  width: 50%;
}
.footer-title {
  font-size: 1.8vw;
  padding: 0;
}
.footer-title-sumavi {
  font-weight: 900;
  padding: 0;
}
.footer-subtitle {
  font-size: 1.2vw;
  font-weight: 700;
  padding: 0;
  color: rgb(141, 247, 141);
}
.footer-left-flex {
  display: flex;
  padding-top: 1vw;
}
.footer-icon {
  height: 1.5vw;
  width: 1.5vw;
}
.footer-image-resize {
  width: 100%;
  height: 100%;
}
.footer-text {
  font-size: 1vw;
  padding: 0;
  padding-left: 0.8vw;
}
.footer-text-right {
  font-size: 1vw;
  padding: 0;
  color: rgb(141, 247, 141);
}
.footer-input {
  width: 100%;
  height: 2.8vw;
  border-radius: 0.8vw;
  margin-top: 0.5vw;
}
.footer-button {
  border-radius: 2vw;
  width: 30%;
  height: 2.8vw;
  border: 2px solid rgb(141, 247, 141);
  margin-top: 1vw;
  background-color: transparent;
  outline: none;
  color: white;
  font-size: 1vw;
  font-weight: 900;
}
.footer-button:hover {
  background-color: rgb(141, 247, 141);
  color: black;
}
.footer-top-padding {
  padding-top: 10vw;
}
@media only screen and (max-width: 768px) {
  .footer-image-container {
    height: 30vh;
  }
  .footer-title {
    font-size: 3.6vw;
  }
  .footer-subtitle {
    font-size: 2.4vw;
  }
  .footer-text {
    font-size: 1vw;
    padding: 0;
    padding-left: 0.8vw;
  }
  .footer-text {
    font-size: 2vw;
  }
  .footer-text-right {
    font-size: 2vw;
  }
  .footer-icon {
    height: 3vw;
    width: 3vw;
  }
}
