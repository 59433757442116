.key-image-container {
  height: 13vw;
  width: 100%;
  background-image: url("../../images/367.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
}
.keypoint-container {
  display: flex;
  align-items: center;
  color: white;
  width: 33.333%;
  height: 50%;
  text-align: center;
}
.keypoint-container-margin {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: 100%;
}
.keypoint-logo-container {
  width: 4.5vw;
  height: 4.5vw;
  object-fit: contain;
  border-radius: 50%;
  background-color: white;
  position: relative;
}
.keypoint-logo-resize {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.keypoint-text {
  font-size: 1vw;
  font-weight: 600;
  margin: 0;
  margin-left: 1vw;
}
.keypoint-wrapper {
  width: 80%;
  height: 100%;
  margin: 0 8%;
  display: flex;
}
.keypoint-button-container {
  position: relative;
  width: 20%;
}

.keypoint-button-custom {
  width: 8vw;
  height: 2.5vw;
  font-size: 0.9vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3vw;
  border-color: white;
}
.btn {
  padding: 0.7vw;
}

.keypoint-button-custom:hover {
  background-color: rgb(71, 219, 162);
  border-color: rgb(71, 219, 162);
}

@media only screen and (max-width: 768px) {
  .key-image-container {
    height: 40vh;
  }
  .keypoint-container {
    display: flex;
    align-items: center;
    color: white;
    width: 50%;
    height: 21%;
    text-align: center;
  }
  .keypoint-container-margin {
    width: 80%;
    margin: 0 auto;
    padding-top: 5vw;
    padding-left: 5vw;
    margin-bottom: 5vw;
  }
  .keypoint-logo-container {
    width: 8vw;
    height: 8vw;
    object-fit: contain;
    border-radius: 50%;
    background-color: white;
    position: relative;
  }
  .keypoint-wrapper {
    display: block;
    width: 100%;
    height: 70%;
    margin: 0;
  }
  .keypoint-text {
    font-size: 2vw;
  }
  .keypoint-button-container {
    width: 100%;
    height: 10vh;
  }

  .keypoint-button-custom {
    width: 16vw;
    height: 5vw;
    font-size: 1.8vw;
    position: absolute;
    top: 10%;
    left: 16.5%;
    transform: translate(-10%, -16.5%);
    border-radius: 3vw;
    border-color: white;
  }
  // .btn {
  //   padding: 0.7vw;
  // }
}
