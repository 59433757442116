@import "~bootstrap/scss/bootstrap";

body {
  font-family: "Gothic A1", sans-serif;
}

.body-padding {
  position: absolute;
  overflow: hidden;
  top: 88px;
}
p {
  margin: 0;
  padding: 1vw 0;
}
