.content-container {
  display: flex;
  width: 100%;
  padding: 4vw 8% 4vw 8%;
}
.content-heading {
  font-size: 2vw;
  font-weight: 900;
}
.content-subheading {
  font-size: 1vw;
  font-weight: 700;
}
.content-left-container {
  width: 45%;
  padding-right: 5%;
}
.hand-image-container {
  width: 100%;
  object-fit: contain;
}
.hand-image-resize {
  max-width: 100%;
  max-height: 100%;
  border-radius: 3vw;
}

.content-right-container {
  width: 60%;
}

.content-text {
  font-size: 1.1vw;
}
@media only screen and (max-width: 768px) {
  .content-container {
    display: block;
    width: 100%;
    padding: 4vw 8% 4vw 8%;
    text-align: center;
  }
  .content-heading {
    font-size: 4vw;
  }
  .content-subheading {
    font-size: 2vw;
  }
  .content-left-container {
    width: 100%;
    padding-right: 5%;
  }
  .hand-image-container {
    width: 60%;
    object-fit: contain;
    margin: 0 auto;
  }
  .content-right-container {
    width: 100%;
  }
  .content-text {
    font-size: 2.2vw;
  }
}
