.background {
  background-color: white;
}
.header-title {
  font-size: 2vw;
}
.header-text {
  font-size: 1vw;
}
@media only screen and (max-width: 768px) {
  .header-title {
    font-size: 4vw;
  }
}
